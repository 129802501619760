import { KeycloakOnLoad } from 'keycloak-js';

export const environment = {
  production: false,
  api: 'https://test.mijnsportvisserij.nl/svnl/api',
  keycloak: {
    url: 'https://test.mijnsportvisserij.nl/auth',
    realm: 'Svnl',
    clientId: 'portal',
    initOptions: {
      onLoad: 'login-required' as KeycloakOnLoad,
      silentCheckSsoRedirectUri: '',
      redirectUri: '',
      checkLoginIframe: false,
    },
  },
  funnel : {
    url : 'https://test.vispas.nl/',
  },
  cancelDisabled: false,
  analytics: {
    ga : {
      enabled: false,
      code: 'DISABLED',
    },
    gtm : {
      enabled: false,
      code : 'DISABLED',
    },
  },
};
