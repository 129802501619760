<mat-nav-list class="svnl-mat-nav-list">
  <mat-list-item [routerLink]="['profile']" routerLinkActive="active" (click)="selectMenuItem()">
    <mat-icon matListItemIcon>person</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.profile' | translate}}</span>
  </mat-list-item>

  <mat-list-item [routerLink]="['permits']" routerLinkActive="active" (click)="selectMenuItem()">
    <mat-icon matListItemIcon>credit_card</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.permits' | translate}}</span>
  </mat-list-item>

  <mat-list-item [routerLink]="['fishingwaters']" routerLinkActive="active" (click)="selectMenuItem()">
    <mat-icon matListItemIcon>list</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.fishingwaters' | translate}}</span>
  </mat-list-item>

  <mat-list-item [routerLink]="['invite']" routerLinkActive="active" (click)="selectMenuItem()">
    <mat-icon matListItemIcon>person_add</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.fishinginvites' | translate}}</span>
  </mat-list-item>

  <mat-list-item [routerLink]="['contact']" routerLinkActive="active"  (click)="selectMenuItem()">
    <mat-icon matListItemIcon>mail</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.contact' | translate}}</span>
  </mat-list-item>

  <mat-list-item [routerLink]="['misconduct']" routerLinkActive="active"  (click)="selectMenuItem()">
    <mat-icon matListItemIcon>report</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.misconduct' | translate}}</span>
  </mat-list-item>

  <mat-list-item *ngIf="electionIds.length > 0" [routerLink]="['elections']" routerLinkActive="active"  (click)="selectMenuItem()">
    <mat-icon matListItemIcon [matBadge]="electionIds.length" matBadgeColor="warn">task_alt</mat-icon>
    <span matListItemTitle>{{'portal.sidebar.menu.elections' | translate}}</span>
  </mat-list-item>

</mat-nav-list>
